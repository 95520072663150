import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import {
	Button,
	CheckBox,
	CustomRadio,
	InputText,
	Message,
	Textarea,
	TitlePage,
} from "../../fields";
import { menuCourse } from "../../data/dataMunu.json";
import Dushboard from "../../layout/Dushboard";
import PageInnerMenu from "../../common/pageInnerMenu";
import { initCourse } from "../../../redux/reducers/course";
import "./style.scss";
import { courseAction } from "../../../redux/actions/courseAction";
import { useHistory } from "react-router-dom";
import { funcCreate, funcDelete, funcEdit } from "../../../controllers";
import { coursesByDayActions } from "../../../redux/actions/coursesByDayActions";
import ImageBlock from "../../ImageBlock/ImageBlock";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";
import ListSearch from "../../common/ListSearch";
import { allBenefitsAction } from "../../../redux/actions/allBenefitsAction";

const radioData = [
	{ label: "Время выполнения", name: "range" },
	{ label: "Колличество попыток", name: "range" },
];

const typesCourse = [
	{ label: "Игры и упражнения", name: "typesCourse" },
	{ label: "Песенки и потешки", name: "typesCourse" },
];

const Course = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { course, allcategory, allbenefits } = useSelector((state) => state);
	const [selectItems, setSlectItems] = useState(course.benefitsLists);
	const [selectItemsCat, setSlectItemsCat] = useState(course.category);
	const history = useHistory();
	const dispatch = useDispatch();
	const defaultVideoSrc = {
		v1: "", // видео с музыкой
		v2: "", // видео без музыки
		v3: "", // видео с субтитрами
		v4: "", // видео без субтитров
	};
	const [arrowVideo, setArrowVideo] = useState(course.video);
	const [isDay, setDay] = useState(course.viewerDay);
	const [isMonth, setMonth] = useState(course.viewerMonth);
	const [isYear, setYear] = useState(course.viewerYear);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [isSelectedRadio, setRadio] = useState("Время выполнения");
	const [isFiles, setFiles] = useState({
		base64: "",
		fileList: {},
	});
	const [visible, setVisible] = useState(false);
	const [visibleCat, setVisibleCat] = useState(false);
	const [typeCourse, setSetTypeCourse] = useState(course.courseType);
	const [isError, setError] = useState("");
	const [isChecked, setChecked] = useState(true);
	console.log('isDay', isDay);
	useEffect(() => {
		if (allcategory.items.length === 0) {
			dispatch(allCategoryAction({ token }));
		}
		if (allbenefits.items.length === 0) {
			dispatch(allBenefitsAction({ token }));
		}
	}, []);
	// eslint-disable-next-line
	const renderRadio = useMemo(() => {
		return radioData.map((radio, idx) => {
			return (
				<CustomRadio
					key={`${radio.label}-${idx}`}
					label={radio.label}
					selectItem={isSelectedRadio}
					click={(label) => setRadio(label)}
				/>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelectedRadio]);

	const renderRadioTypes = useMemo(() => {
		return typesCourse.map((radio, idx) => {
			return (
				<CustomRadio
					key={`${radio.label}-${idx}`}
					label={radio.label}
					selectItem={typeCourse}
					click={(label) => {
						dispatch(courseAction({ ...course, courseType: label }));
						setSetTypeCourse(label);
					}}
				/>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeCourse]);

	const resetData = () => {
		dispatch(courseAction(initCourse));
		dispatch(coursesByDayActions([]));
		setArrowVideo(defaultVideoSrc);
		setSlectItems([]);
		setSlectItemsCat([]);
		setDay("");
		setMonth("");
		setYear("");
		setFiles({
			base64: "",
			files: {},
		});
		location.state = null;
	};

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	// ======================== Запросы на сервер =======
	const saveHandler = async () => {
		const sortByOrder = await handleBreakdownByDays(isDay, "create");

		const formData = new FormData();
		formData.append("name", course.name);
		formData.append("video", JSON.stringify(arrowVideo));
		formData.append("target", course.target);
		formData.append("description", course.description);
		formData.append("category", JSON.stringify(selectItemsCat));

		isSelectedRadio === "Время выполнения"
			? formData.append("leadTime", JSON.stringify(course.leadTime))
			: formData.append("attempts", JSON.stringify(course.attempts));

		formData.append("benefitsLists", JSON.stringify(selectItems));
		formData.append("viewerDay", isDay);
		formData.append("viewerMonth", isMonth);
		formData.append("viewerYear", isYear);
		formData.append("order", 0);
		formData.append("sortByOrder", JSON.stringify(sortByOrder));
		formData.append("image", isFiles.fileList[0]);
		formData.append("bill", isChecked);
		formData.append("courseType", course.courseType);
		formData.append("welcoming", course.welcoming);

		try {
			setLoader(true);
			const res = await funcCreate(
				formData,
				"create-course",
				token,
				"multipart/form-data"
			);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
				resetData();
			}
		} catch (error) {
			setLoader(false);
		}
	};

	const editHandler = async () => {
		let file = Object.keys(isFiles.fileList).length > 0;

		const sortByOrder = await handleBreakdownByDays(course.sortByOrder, "edit");

		const formData = new FormData();
		formData.append("name", course.name);
		formData.append("video", JSON.stringify(arrowVideo));
		formData.append("target", course.target);
		formData.append("description", course.description);
		formData.append("category", JSON.stringify(selectItemsCat));

		isSelectedRadio === "Время выполнения"
			? formData.append("leadTime", JSON.stringify(course.leadTime))
			: formData.append("attempts", JSON.stringify(course.attempts));

		formData.append("benefitsLists", JSON.stringify(selectItems));
		formData.append("viewerDay", isDay);
		formData.append("viewerMonth", isMonth);
		formData.append("viewerYear", isYear);
		formData.append("order", course.order);
		formData.append("sortByOrder", JSON.stringify(sortByOrder));
		formData.append("image", !file ? course.image : isFiles.fileList[0]);
		formData.append("bill", isChecked);
		formData.append("courseType", course.courseType);
		formData.append("welcoming", course.welcoming);

		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"course",
				token,
				location.state.id,
				"multipart/form-data"
			);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};

	// ======================== Обработчики внутри компанента =======

	const cancelingHandler = () => {
		resetData();
		history.goBack();
	};
	const deleteHandler = async () => {
		try {
			setLoader(true);
			const res = await funcDelete("course", token, location.state.id);
			if (res.status === 200) {
				process("Успешно удалено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			}
		} catch (error) {}
	};
	const handlerViewerDay = (val) => {
		setDay(val);
	};

	const handleBreakdownByDays = async (arr, type) => {
		let sortByOrder = [];

		if (type === "create") {
			const days = arr.split(",");

			for await (const iterator of days) {
				sortByOrder = [...sortByOrder, { day: iterator, order: 0 }];
			}

			return sortByOrder;
		}
		if (type === "edit") {
			const days = isDay

			for await (const iterator of days) {
				sortByOrder = [...sortByOrder, { day: iterator, order: 0 }];
			}
			return sortByOrder;
		}
	};
	const handlerViewerMonth = (val) => {
		setMonth(val);
	};
	const handlerViewerYear = (val) => {
		setYear(val);
	};
	const handleBenefits = (item) => {
		!selectItems.includes(item)
			? setSlectItems((selectItems) => [...selectItems, item])
			: setSlectItems((selectItems) => selectItems.filter((el) => el !== item));
		setError("");
	};
	const handleCategory = (item) => {
		!selectItemsCat.includes(item)
			? setSlectItemsCat((selectItemsCat) => [...selectItemsCat, item])
			: setSlectItemsCat((selectItemsCat) =>
					selectItemsCat.filter((el) => el !== item)
			  );
		setError("");
	};
	const hendleLeadTime = (val, name) => {
		let leadTime = {};
		name === "start"
			? (leadTime = {
					start: val,
					end: course.leadTime.end,
			  })
			: (leadTime = {
					start: course.leadTime.start,
					end: val,
			  });

		dispatch(courseAction({ ...course, leadTime: leadTime }));
	};
	const hendleAttempts = (val, name) => {
		let attemptsTime = {};
		name === "start"
			? (attemptsTime = {
					start: val,
					end: course.attempts.end,
			  })
			: (attemptsTime = {
					start: course.attempts.start,
					end: val,
			  });

		dispatch(courseAction({ ...course, attempts: attemptsTime }));
	};

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={menuCourse} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="course">
						{isStatus === true ? (
							<div className="lesson__message">
								<Message message={isMessage} status={isStatus} />
							</div>
						) : null}
						<TitlePage tag="h2" title="Упражнение" />
						<div className="row">
							<div className="col col-6 col-l-12 col--m">
								<InputText
									label={"Упражнение"}
									placeholder="введите название"
									value={course.name}
									onChange={(val) => {
										process("", false);
										dispatch(courseAction({ ...course, name: val }));
									}}
								/>
								<ImageBlock
									files={isFiles}
									src={course.image}
									text="Загрузите скриншот упражнения"
									uploadHandler={setFiles}
								/>
								<InputText
									label={"ID видео с музыкой и с субтитрами"}
									placeholder="ID"
									value={arrowVideo.v1}
									onChange={(val) => {
										setArrowVideo({ ...arrowVideo, v1: val });
									}}
								/>
								<InputText
									label={"ID видео без музыки и с субтитрами"}
									placeholder="ID"
									value={arrowVideo.v2}
									onChange={(val) => {
										setArrowVideo({ ...arrowVideo, v2: val });
									}}
								/>
								<InputText
									label={"ID видео с музыкой и без субтитров"}
									placeholder="ID"
									value={arrowVideo.v3}
									onChange={(val) => {
										setArrowVideo({ ...arrowVideo, v3: val });
									}}
								/>
								<InputText
									label={"ID видео без музыки и без субтитров"}
									placeholder="ID"
									value={arrowVideo.v4}
									onChange={(val) => {
										setArrowVideo({ ...arrowVideo, v4: val });
									}}
								/>
								<div style={{ padding: "10px 0px" }}>
									<CheckBox
										label="Welcoming"
										value={course.welcoming}
										isChecked={course.welcoming}
										onClick={() =>
											dispatch(
												courseAction({
													...course,
													welcoming: !course.welcoming,
												})
											)
										}
									/>
								</div>

								<Textarea
									label={"Описание"}
									value={course.description}
									onChange={(val) =>
										dispatch(courseAction({ ...course, description: val }))
									}
								/>
								<div className="select-wrap">
									<ListSearch
										label="Пособия"
										isVisible={visible}
										selectedItems={selectItems}
										data={allbenefits.items}
										onShow={() => setVisible(!visible)}
										onChange={handleBenefits}
									/>
								</div>
								<div className="select-wrap">
									<ListSearch
										label="Категории"
										isVisible={visibleCat}
										selectedItems={selectItemsCat}
										data={allcategory.items.filter(
											(i) => i.typeCategory === "Упражнения"
										)}
										onShow={() => setVisibleCat(!visibleCat)}
										onChange={handleCategory}
									/>
								</div>
								<div className="course-control">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
							<div className="col col-6 col-l-12 col--m">
								<InputText
									label={"Цель упражнения"}
									placeholder="определите цель"
									value={course.target}
									onChange={(val) =>
										dispatch(courseAction({ ...course, target: val }))
									}
								/>
								<InputText
									label={"Дни показа"}
									placeholder="1,2,3"
									value={isDay}
									onChange={(val) => handlerViewerDay(val)}
								/>
								<InputText
									label={"Месяцы показа"}
									placeholder="1,2,3"
									value={isMonth}
									onChange={(val) => handlerViewerMonth(val)}
								/>
								<InputText
									label={"Год показа"}
									placeholder="1,2,3"
									value={isYear}
									onChange={(val) => handlerViewerYear(val)}
								/>
								<div className="wrap-range row">
									<div className="inner-range col-6">
										{isSelectedRadio === "Время выполнения" ? (
											<div className="range">
												<div className="range__text">Время выполнения</div>
												<div className="range__box">
													<div className="range__item">
														<InputText
															label={"начало"}
															type="Number"
															name="start"
															className="range-input"
															placeholder="min 1"
															value={course.leadTime.start}
															onChange={(val, name) =>
																hendleLeadTime(val, name)
															}
														/>
													</div>
													<div className="range__item">
														<InputText
															label={"конец"}
															type="Number"
															name="end"
															className="range-input"
															placeholder="max 60"
															value={course.leadTime.end}
															onChange={(val, name) =>
																hendleLeadTime(val, name)
															}
														/>
													</div>
												</div>
											</div>
										) : (
											<div className="range">
												<div className="range__text">Количество попыток</div>
												<div className="range__box">
													<div className="range__item">
														<InputText
															label={"начало"}
															type="Number"
															name="start"
															className="range-input"
															placeholder="min"
															value={course.attempts.start}
															onChange={(val, name) =>
																hendleAttempts(val, name)
															}
														/>
													</div>
													<div className="range__item">
														<InputText
															label={"конец"}
															type="Number"
															name="end"
															className="range-input"
															placeholder="max"
															value={course.attempts.end}
															onChange={(val, name) =>
																hendleAttempts(val, name)
															}
														/>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="range-toggle col-6">{renderRadio}</div>
								</div>
								<div className="range">
									<div className="range__text range__text--mb-10">
										Тип упражнения
									</div>
									{renderRadioTypes}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default Course;
