import { GET_CHILDREN } from "../actionTypes";

const initChildrens = {
    items : []
}

export const childrens = (state = initChildrens, action) => {

    switch (action.type) {
        case GET_CHILDREN:
            return {
                 ...state,
                 items : action.payload
            }

        default: return state
    }
}
